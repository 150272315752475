<template>
	<div style="height: 100vh;">
        <iframe :src="'https://apis.map.qq.com/uri/v1/routeplan?type=drive&from=我的位置&fromcoord='+fromcoord+'&to='+title+'&tocoord='+tocoord+'&policy=1&referer='+key" frameborder="0" width="100%" height="100%"></iframe>
	</div>
</template>

<script>    
    import axios from 'axios'
    
	export default {
		data() {
			return {
                key:'JASBZ-BFT3F-LZYJX-NTUXJ-FMAVK-GSFSL',
                title:'兴义市第四中学',
                tocoord:'25.096991,104.899523', // 终点位置
                fromcoord:'',
                src:''
			}
		},
        mounted() {
            this.getIpDingwei()
        },
		methods:{
            
            getIpDingwei(){
                let url = 'https://wlz.qxnyou.com/txmap/ip_location'
                
                axios.get(url).then((res) => {
                    // if(res.status == 0){
                    //     this.fromcoord = res.result.location.lat + ',' + res.result.location.lng
                        
                    //     this.src = 'https://apis.map.qq.com/uri/v1/routeplan?type=drive&from=我的位置&fromcoord='+this.fromcoord+'&to='+this.title+'&tocoord='+this.tocoord+'&policy=1&referer='+this.key
                    // }else{
                    //     alert('未获取到您的位置')
                    // }
                    
                    this.fromcoord = res
                })
            }
            
        }
	}
</script>

<style>
	
</style>
